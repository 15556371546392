.marker-container {
    /* display: flex;
    position: absolute;
    z-index: 999;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%;
    background-color: transparent;
    top: 38px */
}

.marker-image {
    height: 43px;
    position: absolute;
    z-index: 999;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0);
}
